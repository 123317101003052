.item {
  list-style-type: none;
  margin-left: -20px;
}

.link {
  color: var(--ps-blue-primary);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
