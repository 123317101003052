.invitesTable {
  margin: 20px 0;
  width: 100%;
}

/* todo: make sure this style works (it doesn't) */
.invitesTableEmptyMessage {
  text-align: center;
  color: var(--ps-ui-foreground-text);
}
