.progressBarWrapper {
  height: 26px;
  border-radius: 3px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 2em;
}

.progressBar {
  background: #12bcfe;
  border-radius: 3px;
  height: 26px;
  position: relative;
}

.legend table {
  font-size: 14px !important;
  width: 100%;
  color: white !important;
  border: 1px solid #38383a !important;
}

.legend table th:first-child,
.legend table td:first-child {
  width: 25px;
  padding-left: 10px;
}

.legend table td > div {
  border: none !important;
}

.legend table tr:nth-child(2n + 1) td {
  /* border-bottom: 1px solid #ccc; */
  background-color: #38383a;
}

.legend span {
  display: flex;
  padding: 0 4px;
}

.legend span > span {
  flex: 1;
}

.link {
  color: white;
}
