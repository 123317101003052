.loadingSpinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner > * {
  transform: scale(4);
}
