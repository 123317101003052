.input {
  width: 480px;
}

.description {
  font-size: 0.9em;
}

.errors {
  color: #ffebec;
  border: 1px solid #e13238;
  background-color: #a42328;
  border-radius: 4px;
  padding: 10px;
  font-size: 0.9em;
}
