.integrations {
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center; */
  padding: 0;
  margin: 0;
}
.integration {
  display: block;
  margin-right: 10px;
}

.integrationButton {
  min-width: 60px;
}

.keyButton {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.keyButton,
.addressButton {
  margin-top: -5px;
}

.addressButton {
  text-align: left;
}

.dialogFooter {
  display: flex;
  /* so that the button is on the right side */
  justify-content: space-between;

  /* align text and button */
  align-content: center;
}

.listeningWrapper {
  display: flex;
  align-items: center;
}
