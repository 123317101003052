.entityIcon {
  display: inline-block;
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.66);
  vertical-align: baseline;
  font-weight: bold;
  text-align: center;
}

.organization {
  border-radius: 20%;
}

.person {
  border-radius: 50%;
}
