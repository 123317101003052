.planBox {
  padding: 0 1em;
  display: flex;
  width: 50%;
}

.link {
  color: #007bff;
}

h2.sectionHeader {
  /* margin-top: 1.5em; */
}

.planInfo {
  width: 100%;
}

.planName {
  flex: 3;
  font-size: 1.2em;
}

.planButtons {
  flex: 1;
  padding: 1em 0 0;
  text-align: right;
}

.planHeader {
  display: flex;
  border-bottom: 1px solid var(--ps-ui-border);
  padding: 0 1em;
  margin: 0 -1em;
}

.hr {
  border: none;
  border-bottom: 2px solid var(--ps-ui-border);
  margin: 2em 0;
}
