.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  color: var(--ps-neutral-20);
  z-index: 2000;
}

.modalContainer {
  width: 520px;
  margin: 20vh auto 0;
  background: #222;
  box-shadow: 0px 16px 16px -16px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modalHeader {
  padding: 15px 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
}

.modalHeading {
  flex: 1;
  margin: 0;
}

.closeButton {
  border: none;
  padding: 0;
  width: 34px;
  height: 34px;
  cursor: pointer;
  position: relative;
}

.closeButton::after {
  /* useful to visualize hit area: */
  /* background-color: red; */
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 1.5em;
  position: absolute;
  content: '\00d7';
  top: -4px;
  right: -10px;
}

.modalContent {
  padding: 0 20px 20px;
}
