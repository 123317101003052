.menuItemWithButton {
  display: flex;
  width: 100%;
  align-items: center;
}

.menuItemWithButtonTitle {
  color: var(--ps-c-white);
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.menuItemWithButtonButton {
  padding-top: 0;
  padding-bottom: 0;
  color: var(--ps-c-white);
}
