/* TODO(petethepig): hacky, need a better way of doing this */
.sidebar > * > * {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.logo {
  display: flex;
  align-items: center;
  padding: 11px 0 9.2px 9.2px;
  margin: 0;
}

.logoText {
  color: var(--ps-c-white);
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  margin-left: 0;
}

.logoTextCollapsed {
  display: none;
}

.collapseIcon svg {
  animation: swing ease-in-out 0.5s 1 alternate;
  transition: transform 0.3s;
}

.collapsedIconCollapsed svg {
  transform: rotate(-180deg);
}

.throttlingStatusOK {
}

.throttlingStatusWarning {
  color: #e9bc19;
}

.throttlingStatusThrottled {
  color: #e13238;
}
